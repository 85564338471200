@media (min-width: 1400px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  body {
    margin: 0 auto;
    /* width: 1920px; */
  }
}

@media (min-width: 1200px) {
  /* HEADER */
  /* .main-header .container {
    justify-content: flex-start;
  }
  .nav-show,
  .nav-close {
    display: none;
  } */

  .header-nav {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
  }

  /* .nav-links > ul {
    display: flex;
    justify-content: space-between;
  } */

  .nav-links li {
    color: var(--color-white);
    padding: 13px 20px;
    border-radius: 8px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
  }

  .nav-links li.sub-menu-parent {
    border-radius: 8px 8px 0 0;
    position: relative;
  }

  /* .header-nav .nav-links > li.sub-menu-parent:hover {
    background: linear-gradient(265.74deg, #1179BF66 -10%, #012D6C 50%);
  } */

  .nav-links .sub-menu {
    border-radius: 0 0 8px 8px;
    display: block !important;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: -1;
    /* transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; */
  }

  .nav-links .sub-menu-parent .caret-image {
    position: relative;
    bottom: 0px;
    margin-left: 0.9rem;
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
  }

  .nav-links li.sub-menu-parent:hover .caret-image {
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg) !important;
  }

  .header-nav .nav-links .sub-menu li:hover {
    background: transparent;
  }

  .nav-links .sub-menu li {
    font-weight: 400;
    /* text-shadow: #012D6Cb3 2px 0 10px; */
  }

  .nav-links .sub-menu-parent:focus .sub-menu,
  .nav-links .sub-menu-parent:focus-within .sub-menu,
  .nav-links .sub-menu-parent:hover .sub-menu {
    position: absolute;
    visibility: visible;
    display: block;
    opacity: 1;
    z-index: 1;
    /* transition-delay: 0s, 0s, 0.3s;  */
  }

  .nav-links .sub-menu-parent:hover .sub-menu {
    /* background: linear-gradient(
      93.29deg,
      rgba(255, 255, 255, 0.1) 1.54%,
      rgba(255, 255, 255, 0) 127.77%
    ); */
    background: linear-gradient(265.74deg, #1179bf66 -10%, #012d6c 50%);
  }

  /* .nav-links a:hover {
    background-color: #f8f8f8;
    color: #f9ae3b;
  } */

  .nav-buttons .login-button {
    background: linear-gradient(
      93.29deg,
      rgba(255, 255, 255, 0.1) 1.54%,
      rgba(255, 255, 255, 0) 127.77%
    );
    color: var(--color-white);
  }
  .nav-buttons .login-button:hover {
    background: linear-gradient(
      93.29deg,
      rgba(255, 255, 255, 0.35) 1.54%,
      rgba(255, 255, 255, 0) 127.77%
    );
  }

  .nav-buttons .register-button {
    background-color: var(--color-yellow-1);
    color: white;
    border-radius: 0 15px 15px 0;
  }
  .nav-buttons .register-button:hover {
    background: linear-gradient(
      180deg,
      rgba(249, 174, 59, 0.7) 0%,
      rgba(249, 174, 59, 0.7) 100%
    );
  }

  /* PUBLISHER */
  .publisher-reasons-section .reason-image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    max-height: 480px;
  }

  /* PUBLISHER */

  /* ABOUT US PAGE */
  .about-mission-section .reason-image {
    background-size: contain;
  }
  /* ABOUT US PAGE */
}

@media (max-width: 1199px) {
  .main-header {
    padding: 0 3rem;
  }
  .arrow {
    display: none;
  }
  .hero-form {
    display: none;
  }
  .nav-links li.sub-menu-parent {
    border-radius: 8px 8px 0 0;
    position: relative;
  }

  .nav-links .sub-menu-parent .caret-image {
    position: absolute;
    right: 2rem;
  }

  .rotate {
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg) !important;
  }

  .header-nav .nav-links .sub-menu li:hover {
    background: transparent;
  }

  .header-nav.active .nav-links .sub-menu li {
    border-bottom: none;
  }

  .header-nav.active .nav-links .sub-menu li:not(:last-child) {
    padding: 0 0 35px 0;
  }
  .header-nav.active .nav-links .sub-menu li:last-child {
    padding: 0;
  }

  .nav-links .sub-menu li a {
    font-weight: 300;
  }

  .nav-links .sub-menu-parent:focus .sub-menu,
  .nav-links .sub-menu-parent:focus-within .sub-menu,
  .nav-links .sub-menu-parent:hover .sub-menu {
    position: relative;
    top: 0;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }

  .nav-links .sub-menu-parent:hover .sub-menu {
    background: transparent;
  }
}

@media (min-width: 768px) {
  .timeline-container:before {
    top: 0;
    left: 50%;
    margin-left: -1px;
  }
  .timeline-block {
    width: -webkit-calc(50% + 8px);
    width: -moz-calc(50% + 8px);
    width: calc(50% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
  }

  .timeline-block-right {
    float: right;
  }

  .timeline-block-left {
    float: left;
    direction: rtl;
  }
  .monetize-section-cards div img {
    float: left;
    margin: 8px 25px 0 0;
  }

  .panel > div {
    margin-bottom: 20px;
  }
  .publisher-half-content {
    margin-top: -60px !important;
  }
  .publisher-half-content h2 {
    margin-bottom: 0;
    margin-top: 40px;
  }
  .publisher-half-content p {
    font-size: 1rem;
    line-height: 21px;
  }
  .timeline-block-left h3::after {
    right: -15px;
    left: 100%;
    top: 24px;
    transform: rotate(0deg);
  }
  /* ABOUT US PAGE */

  .about-ticker-section p {
    white-space: nowrap;
    border-right: 3px solid #fff;
    animation: typewriter 5s steps(33) 1s normal both,
      blinkTextCursor 500ms steps(33) infinite normal;
  }
  /* .about-ticker-section p:after {
    content: '';
    animation: spiniiii 2s linear infinite;
  } */
  /* ABOUT US PAGE */
}

@media (min-width: 600px) {
  .sign-up-form .half-input {
    display: inline-block;
    width: 49.5%;
  }
  /* careers page */

  /* careers page */
}

/* PUSH NOTIFICATION ADS PAGE */
@media (min-width: 1000px) {
  /* Events section  */
  .new-events a {
    border-radius: 16px;
  }
  .new-events a:before {
    opacity: 0;
  }
  .partialBorder {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  /* Events section */
  /* BLOG POSTS */
  .blog-posts-section .card:hover :before {
    opacity: 0;
  }
  .blog-posts-section .card:last-child {
    margin-right: 0;
  }
  .blog-posts-section .card .content .call-action:hover {
    opacity: 0.7;
  }
  /* BLOG POSTS */
  /* NEW EVENTS GRID  */
  .new-events {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.5rem;
    height: 30rem;
  }
  .event-1 {
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    height: 100%;
  }
  .event-2 {
    grid-area: 2 / 1 / 3 / 2;
    width: 100%;
    height: 100%;
  }
  .event-3 {
    grid-area: 1 / 2 / 3 / 4;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .event-4 {
    grid-area: 1 / 4 / 2 / 5;
    width: 100%;
    height: 100%;
  }
  .event-5 {
    grid-area: 2 / 4 / 3 / 5;
    width: 100%;
    height: 100%;
  }
  /* GRID FOR CAREERS OFFICES */
  .offices-section .new-events {
    height: 430px;
    max-width: 1500px;
  }
  .offices-section .new-events div {
    border-radius: 15px;
  }
  /* NEW EVENTS GRID  */

  /* HERO SECTION */

  /* .hero-section .container {
    padding: 10rem 20px 100px;
  } */

  .hero-section-publisher .container {
    padding-bottom: 0;
  }

  .hero-image {
    display: block;
    flex: initial;
    width: 50%;
  }

  /* FEATURES SECTION */

  .features-section .container {
    flex-direction: row;
  }

  /* .features-section .card {
    width: 398px;
    max-width: 380px;
    max-height: 360px;
    min-width: 0;
    margin-top: -80px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .features-section .card:last-child {
    margin-right: 0px;
  } */

  /* FOR PUBLISHER PAGE */
  .publisher-monetization-grey .card {
    /* margin-top: -120px; */
    margin-top: 0;
    margin-right: 50px;
    margin-bottom: 0;
  }

  .publisher-monetization-grey .card:last-child {
    margin-right: 0px;
  }

  .reasons-list-container-publisher {
    width: 58%;
    display: inline-block;
    position: relative;
    right: -120px;
  }
  .reasons-list-container {
    width: 45%;
    display: inline-block;
  }
  .reasons-list-container .call-action {
    position: relative;
    left: 0%;
    transform: translate(0%, 0%);
  }
  .reason-image {
    width: 52%;
    display: inline-block;
    height: 630px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .reasons-arrow {
    bottom: -38px;
    left: 47px;
  }
  #publisher-slider-button-prev {
    left: 0;
  }
  .publisher-reasons-section ul li {
    float: left;
  }
  /* FOR PUBLISHER PAGE */
  .features-section .additional-icons {
    display: block;
    position: absolute;
    bottom: -30px;
  }

  .features-section .additional-icons img {
    margin: 10px;
  }
  .tab {
    width: 25%;
    padding: 25px 0;
    line-height: 1;
    margin: 5px;
    display: inline-block;
  }
  /* CAREERS PAGE */
  /* .careers-hero-section .container {
    padding: 20px 20px 0;
  } */

  /* CAREERS PAGE */
  /* HERO SECTION */
  .push-hero-section .container {
    padding-bottom: 300px;
  }

  .push-hero-section .hero-image {
    display: flex;
    justify-content: flex-end;
  }
  .push-hero-section .hero-image img {
    width: 80%;
  }

  /* PUBLISHER PAGE */
  .revenue-content {
    width: 50%;
    margin-left: auto;
  }
  .publisher-timeline-section {
    display: flex;
  }
  .timeline-container {
    width: 55%;
    margin: 0 auto;
    padding: 10px;
  }
  .timeline-block {
    margin-bottom: 0;
  }
  .publisher-case-studies .container {
    display: flex;
    width: 90%;
    margin: 0 auto;
  }
  .publisher-contact-section {
    position: relative;
    overflow: hidden;
    height: 1000px;
  }
  .publisher-contact-sm-section {
    height: 730px;
  }
  .div-wrapp-about-us {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
  }
  .div-wrapp-about-us > div {
    display: flex;
    padding: 16px 0;
    border-bottom: 2px solid #f5f5f5;
    flex-direction: row;
    align-items: center;
  }
  .contact-info-details {
    margin-left: 30px;
  }
  .contact-info-details p {
    color: #012d6c;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .contact-info-details span {
    color: #9b9b9b;
    line-height: 1.8;
  }
  .publisher-contact-form {
    position: relative;
    background-color: #012d6c;
    float: right;
    width: 45%;
    display: inline-block;
    height: 100%;
  }
  .publisher-contact-section #stars,
  .publisher-contact-section #stars2,
  .publisher-contact-section #stars3 {
    display: block;
  }
  .publisher-contact-form input,
  .publisher-contact-form textarea {
    width: 350px;
    transition: all 0.4s;
  }
  .publisher-contact-form form {
    height: 600px;
    padding-top: 70px;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 550px;
    left: -100px;
  }
  .publisher-contact-sm-form form {
    height: 360px;
  }
  .publisher-contact-form input:focus,
  .publisher-contact-form textarea:focus {
    width: 380px;
  }
  .satelite-image {
    position: absolute;
    top: 124px;
    border-radius: 50%;
    display: block;
    z-index: 1;
    left: 310px;
    padding: 20px;
  }
  .satelite-full-path {
    position: absolute;
    top: 49px;
    left: -261px;
    display: block;
    height: 762px;
  }
  .satelite-overflow-path {
    position: absolute;
    bottom: 211px;
    display: block;
    z-index: 1;
    height: 77px;
    transform: rotate(351deg);
    left: 90px;
  }
  /* margin-right: 0;
  }
  /* PUBLISHER PAGE */

  /* ABOUT US PAGE */
  .hero-section-about-us {
    text-align: left;
  }
  .hero-section-about-us .reasons-list-container {
    padding-left: 5%;
  }
  .hero-section-about-us h1 {
    text-align: left;
  }

  .hero-section-about-us p {
    max-width: 584px;
    text-align: left;
  }
  .about-mission-section h2 {
    text-align: left;
  }
  .about-mission-section p.about-mission-yellow {
    max-width: 890px;
    margin: 40px auto 180px;
  }
  .about-us-goals-section .card {
    margin-top: -100px;
  }
  /* ABOUT US PAGE */
  /* REGISTER */
  #logo-img-container {
    top: 30px;
    left: 30px;
    right: auto;
    height: auto;
  }
}

@media (min-width: 768px) {
  .push-discover-section > div {
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
  }

  .push-discover-section img {
    display: block;
    width: 50%;
  }

  .push-discover2-section {
    padding: 30px 70px;
  }
  .extension-fast-section {
    padding: 30px 70px;
  }
  .cpa-assistance-section {
    padding: 50px 70px 0;
  }

  .about-us-time-section {
    padding: 30px;
  }
  .two-column-section .row {
    flex-direction: row;
    justify-content: space-evenly;
    margin: 30px auto 50px;
  }

  .two-column-section .row.image-right {
    flex-direction: row;
  }

  .two-column-section .row > div {
    width: 50%;
  }

  .two-column-section .row img {
    width: 100%;
    height: 100%;
    max-height: 450px;
  }

  /* .two-column-section .row.image-fix {
    margin-top: -10%;
  } */
}

@media (min-width: 1200px) {
  .push-discover-section > div {
    justify-content: flex-end;
  }

  .push-discover-section img {
    position: absolute;
    left: -80px;
    width: 650px;
  }
  .career-ceo-section img {
    left: 0px;
  }
  /* CAREERS PAGE  */
  .careers-perks .container {
    justify-content: space-between;
    margin: 100px auto 250px;
  }
  .careers-perks img {
    display: block;
  }

  /* CAREERS PAGE  */
}

/* CONTACT US PAGE */

@media (min-width: 1000px) {
  /* index page */
  /* .hero-section {
    padding: 0 70px;
    text-align: left;
  } */
  .features-section .card {
    width: 340px;
    max-width: 340px;
    max-height: 330px;
  }
  .monetize-section {
    padding: 0 70px;
  }

  /* push page */

  .push-blend-push-ads-section {
    padding: 130px 70px;
  }
  .push-supported-browsers {
    padding: 30 70px;
  }
  /* cpa page */
  .hero-section-cpa {
    padding: 0 70px;
  }
  /* smartlink page */
  .smartlink-what-are-section {
    padding: 40px 70px 0;
  }
  .smartlink-instant-income-section .step {
    padding: 0 70px;
  }
  .smartlink-instant-income2-section {
    padding: 0 70px;
  }
  /* extension page */
  .push-supported-browsers .browsers {
    padding: 0 70px;
  }
  /* native ads page */
  .native-details-section,
  .native-boost-section,
  .native-features-section {
    padding: 0 70px;
  }
  .native-advantages-section {
    padding: 80px 70px;
  }
  .native-ad-formats-section,
  .native-advantages2-section {
    padding: 20px 70px;
  }
  /* about us page */
  .about-us-time-section {
    padding: 30px 70px;
  }

  .contact-form-section > div {
    width: 50%;
  }

  .contact-form-section .form-wrapper {
    text-align: left;
  }
}
@media (min-width: 1400px) {
  /* ABOUT US SECTION */
  .about-ticker-section p {
    font-size: 4.1875rem;
    line-height: 81px;
  }
  /* ABOUT US SECTION */
  .contact-form-section > div {
    padding-left: 100px;
  }
  .smartlink-what-are-section {
    padding: 100px 70px 100px;
  }
  .smartlink-instant-income2-section {
    padding: 0 70px;
  }
  .smartlink-instant-income2-section.two-column-section .row {
    margin-bottom: 200px;
  }
  .extension-fast-section div.row {
    margin-bottom: 100px;
  }
  .about-mission-section .reason-image {
    /* margin-top: 5%; */
  }
}
/* SMARTLINKS PAGE */
@media (min-width: 1000px) {
  .smartlink-instant-income-section > div {
    flex-direction: row;
  }

  .smartlink-instant-income-section .line {
    display: block;
    width: 100%;
    height: 0;
    border-top: 5px solid #012d6c;
    margin: 5px;
  }

  .smartlink-instant-income-section .step img {
    width: auto;
  }
}

/* NATIVE ADS PAGE */
@media (min-width: 768px) {
  .academy-montenegro p {
    text-align: left;
    margin-left: 50px;
  }
  .join-section-centered h2 {
    font-size: 3.1875rem;
    line-height: 61px;
  }
  .native-advantages-section > p,
  .native-media-outlets-section h2 {
    font-size: 4.1875rem;
    line-height: 81px;
  }
}

@media (min-width: 1000px) {
  .native-hero-section h1 {
    padding-right: 20px;
  }

  .native-media-outlets-section {
    padding-bottom: 350px;
  }

  .native-features-section {
    background: white;
  }

  .native-features-section .card {
    margin-top: -250px;
  }
}

@media (min-width: 1200px) {
  .native-boost-section img {
    display: block;
  }

  .native-advantages-section .big-image {
    display: block;
    width: 50%;
    padding-right: 20px;
  }
}

/* AD FORMATS PAGE */
@media (min-width: 450px) {
  .ad-formats-main-section .tab-button {
    width: 398px;
  }
}

@media (min-width: 1000px) {
  .ad-formats-main-section .tab-button {
    margin-top: -56px;
    margin-right: 20px;
    margin-bottom: 0;
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .ad-formats-main-section .display-area {
    flex-wrap: nowrap;
  }

  .ad-formats-main-section .left-buttons {
    order: 1;
  }

  .ad-formats-main-section .display-area .image {
    order: 2;
  }

  .ad-formats-main-section .right-buttons {
    order: 3;
  }

  .ad-formats-main-section .widget-button::before,
  .ad-formats-main-section .widget-button::after {
    content: '';
    position: absolute;
    border-style: solid;
    left: 100%;
  }

  .ad-formats-main-section .widget-button::before {
    border-width: 10px 0 10px 30px;
    border-color: transparent transparent transparent #98c5e3;
    top: calc(50% - 10px);
  }

  .ad-formats-main-section .widget-button::after {
    border-width: 6px 0 6px 22px;
    border-color: transparent transparent transparent white;
    top: calc(50% - 10px + 4px);
  }

  .ad-formats-main-section .right-buttons .widget-button::before,
  .ad-formats-main-section .right-buttons .widget-button::after {
    transform: rotate(180deg);
    left: -30px;
  }

  .ad-formats-main-section .right-buttons .widget-button::after {
    left: -22px;
  }

  .ad-formats-main-section .widget-button.active:before,
  .ad-formats-main-section .widget-button.active:after {
    border-left-color: #f9ae3b;
  }
}
@media (max-width: 1400px) {
  .two-column-section .row .image,
  .push-discover-section img,
  .ad-formats-main-section .display-area,
  .timeline-container {
    transform: scale(0.8);
  }
  .cpa-assistance-section h2 {
    margin: 0 auto 0;
  }
}
@media (max-width: 1000px) {
  .hero-text {
    text-align: left;
  }

  /*
  .hero-form {
    display: none;
  }
  */
  .two-column-section .row .image.push-discover-section img,
  .ad-formats-main-section .display-area,
  .timeline-container {
    transform: scale(1);
  }
  .cpa-assistance-section h2 {
    margin: 0 auto 60px;
  }
  .main-header {
    padding: 0 3rem;
  }
  .monetize-section.flex-box > div:first-of-type {
    order: 0;
  }
  .monetize-section.flex-box > div:nth-of-type(2) {
    order: 1;
  }
  .monetize-section.flex-box > div:nth-of-type(3) {
    order: 3;
  }
  .monetize-section.flex-box > div:nth-of-type(4) {
    order: 2;
  }
  .monetize-section.flex-box > div:nth-of-type(5) {
    order: 4;
  }
  .monetize-section.flex-box > div:nth-of-type(6) {
    order: 5;
  }
}
@media (max-width: 768px) {
  .main-header {
    padding: 0 1.5rem;
  }
  .publisher-carousel-section .arrow-right,
  .publisher-carousel-section .arrow-left {
    display: none;
  }

  .sponsors-section-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Contact */

@media (max-width: 800px) {
  .inner-box {
    border-radius: 0;
    grid-template-columns: 1fr;
    padding: 10% 5%;
    margin: 1rem 0 0 0;
    grid-row-gap: 2rem;
  }
  .inner-box .information-wrapper {
    padding: 0;
    text-align: center;
  }

  .map-container iframe {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 300px;
  }
}

@media (max-width: 500px) {
  .inner-box {
    padding: 2rem 0;
  }
}

/* FAQ Section*/

@media (max-width: 1024px) {
  .publisher-carousel-section .container {
    padding: 6rem;
  }
}

@media (max-width: 599px) {
  .product-accordion section > div:first-of-type,
  .product-accordion section > div:first-of-type:hover,
  .product-accordion section > div:last-of-type,
  .product-accordion section > div:last-of-type:hover {
    border-radius: 0;
  }

  .publisher-carousel-section .container {
    padding: 3rem;
  }

  #customers-testimonials .owl-item:not(.active) {
    padding: 0;
  }
}
